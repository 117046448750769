
import { AuthServiceService } from './../servicos/autenticacao/auth-service.service';
import { Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
  Router
} from '@angular/router';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class AuthGuard {

  constructor(
    private authService: AuthServiceService,
    private router: Router
  ) { }

  canActivate(
    route: ActivatedRouteSnapshot,
    // tslint:disable-next-line: no-shadowed-variable
    state: RouterStateSnapshot
  ): Observable<boolean> | boolean {
    if (localStorage.getItem('token')) {
      return true;
    }
    this.router.navigate(['/auth']);
    return false;
    // return this.authService.logado.pipe(map(logado => {
    //   if (logado) {
    //     return logado;
    //   } else {
    //     this.router.navigate(['/auth']);
    //     return false;
    //   }
    // }));
  }

}
