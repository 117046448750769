import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router } from '@angular/router';

import { AuthServiceService } from 'src/app/servicos/autenticacao/auth-service.service';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})

export class OnlineGuard {

  constructor(
    private auth: AuthServiceService,
    private router: Router
  ) {}

  canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot)
  : Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    if (localStorage.getItem('per_on')) {
      return true;
    }
    this.router.navigate(['/']);
    return false;
  }

}
