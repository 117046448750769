import { enableProdMode, LOCALE_ID, importProvidersFrom } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';


import { environment } from './environments/environment';
import { AppComponent } from './app/app.component';
import { provideAnimations } from '@angular/platform-browser/animations';
import { ReactiveFormsModule } from '@angular/forms';
import { AppRoutingModule } from './app/app-routing.module';
import { BrowserModule, bootstrapApplication } from '@angular/platform-browser';
import { withInterceptorsFromDi, provideHttpClient } from '@angular/common/http';
import { MAT_DATE_LOCALE } from '@angular/material/core';
import { EncryService } from './app/servicos/crypt/encry.service';
import { AuthGuard } from './app/guard/auth-guard';
import { AuthServiceService } from './app/servicos/autenticacao/auth-service.service';
import { TimePipe } from './app/pipes/time.pipe';
import { DatePipe, CurrencyPipe, APP_BASE_HREF } from '@angular/common';

if (environment.production) {
  enableProdMode();
}

bootstrapApplication(AppComponent, {
    providers: [
        importProvidersFrom(BrowserModule, AppRoutingModule, ReactiveFormsModule),
        DatePipe,
        CurrencyPipe,
        TimePipe,
        AuthServiceService,
        AuthGuard, EncryService,
        { provide: LOCALE_ID, useValue: 'pt' },
        { provide: MAT_DATE_LOCALE, useValue: 'pt' },
        { provide: APP_BASE_HREF, useValue: '/gnew' },
        provideHttpClient(withInterceptorsFromDi()),
        provideAnimations()
    ]
})
  .catch(err => console.error(err));
